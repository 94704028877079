import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import Attendance from "../../models/Attendance";
import AttendanceRow from "./AttendanceRow";

const useStyles = makeStyles(() =>
  createStyles({
    thead: {
      background: "#25272E",
      color: "white",

    },
    tbody: {
      background: "white",
    },
    scoll: {
      height: 377,
      width: "100%",
      overflow: "auto",
    },
    root: {
      paddingTop: "1%",

    },
  })
);

interface AttendacneTableProps {
  getAttendances: any;
  attendances: Attendance[];
  attendance: Attendance | undefined;
  loading: boolean;
  editNoteClient: any;
  refreshData: number;
}

function AttendanceTable({
  getAttendances,
  attendances,
  attendance,
  loading,
  editNoteClient,
  refreshData
}: AttendacneTableProps) {
  const classes = useStyles();
  const [refresh, setRefresh] = useState<Date>(new Date())
  const scrollBar = document.getElementById("scroll-row");

  useEffect(() => {
    getAttendances(moment(new Date()).format("YYYY-MM-DD"), moment(new Date()).format("YYYY-MM-DD"));
    //console.log(refresh)
    const timer = setTimeout(() => {
      setRefresh(new Date())
    }, 60000);
    return () => clearTimeout(timer);
  }, [getAttendances, attendance, refresh]);

  useEffect(() => {
    if (!loading) {
      if (scrollBar !== null) {
        const key = attendances.findIndex((e) => e.employeeId === attendance?.employeeId) + 1;
        const half = (scrollBar.scrollHeight / (attendances.length + 1)) * 3;
        scrollBar.scrollTop = (scrollBar.scrollHeight / (attendances.length + 1)) * key - half;
      }
    }
  }, [loading, attendances, scrollBar, attendance]);

  useEffect(() => {
    setRefresh(new Date())
  }, [refreshData]);

  return (
    <div className={classes.root}>
      <TableContainer id="scroll-row" className={classes.scoll}>
        <>
          <Table stickyHeader aria-label="sticky table">
            <colgroup>
              <col style={{ width: "5%" }} />
              <col style={{ width: "12.5%" }} />
              <col style={{ width: "12.5%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell className={classes.thead}>#</TableCell>
                <TableCell className={classes.thead}>Name</TableCell>
                <TableCell className={classes.thead}>Check-in</TableCell>
                <TableCell className={classes.thead}>Check-out</TableCell>
                <TableCell className={classes.thead}>Include Break</TableCell>
                <TableCell className={classes.thead} align="center">Note</TableCell>
                <TableCell className={classes.thead}>Action</TableCell>
                <TableCell className={classes.thead}>Summary</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tbody}>
              {
                attendances.map((m, key) => {
                  if (attendance !== undefined && m.employeeId === attendance.employeeId && m.checkOut !== null) {
                    return (
                      <AttendanceRow
                        count={key + 1}
                        attendance={m}
                        lastRowClass="newCheckIn"
                        editNoteClient={editNoteClient}
                        key={m.count}
                      />
                    );
                  }
                  else if (attendance !== undefined && m.employeeId === attendance.employeeId && m.checkOut === null) {
                    return (
                      <AttendanceRow
                        count={key + 1}
                        attendance={m}
                        lastRowClass="newCheckOut"
                        editNoteClient={editNoteClient}
                        key={m.count}
                      />
                    );
                  }
                  else {
                    return (
                      <AttendanceRow
                        count={key + 1}
                        attendance={m}
                        editNoteClient={editNoteClient}
                        key={m.count}
                      />
                    );
                  }
                })
              }
            </TableBody>
          </Table>
        </>
      </TableContainer>
    </div>
  );
}

export default AttendanceTable;