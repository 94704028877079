import axios from 'axios';

const API_URL = process.env.REACT_APP_WEATHER_API_URL;

const WeatherAPI = {
    getApi: () => {
        return axios.get(API_URL + "lat=18.796258&lon=98.9679742&appid=94f6ca7e47eba0490bae4c2ccec4358c");
    },
}

export default WeatherAPI;