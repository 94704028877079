import { Container, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { useEffect } from "react";
import useWeatherAPI from "../../hook/useWeatherAPI";

function Weather() {
    const [data, getWeather] = useWeatherAPI();

    useEffect(() => {
        getWeather();
    }, [getWeather]);

    return (
        <Container>
            <Grid container direction="row" justify="space-around" alignItems="center">
                <Grid item xs={12} md={6} lg={6}>
                    <Typography component="h5" variant="h5" align="center">
                        <img alt="img" width="70px" src={`http://openweathermap.org/img/wn/${data.weather[0].icon}.png`}></img>{data.weather[0].main}<br />
                        <Typography component="h3" variant="h3">{(data.main.temp - 273.15).toFixed(1)}<span>&#8451;</span></Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Typography align="center">
                        Humidity : {data.main.humidity} % <br />
                        Sunrise : {moment.unix(data.sys.sunrise).format('HH:mm')}<br />
                        Sunset : {moment.unix(data.sys.sunset).format('HH:mm')}<br />
                        {data.name}, {data.sys.country}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Weather;