import { axiosInstance } from "../helper/axios";

export interface Auth {
  email: string;
  password: string;
}

const authApi = {
  getToken: (auth: Auth) => {
    return axiosInstance.post<string>("api/Users/Login", auth);
  }  
};

export default authApi;
