import { useState } from "react";
import AttendanceForm from "../../models/AttendanceForm";
import Attendance from "../../models/Attendance";
import { Box, Button, createStyles, Grid, makeStyles, TextField, Typography, Theme, CircularProgress } from "@material-ui/core";
import { Face } from "@material-ui/icons";
import Alert from "../Alert/Alert";
import Loading from "../../components/Loading/Loading";
import React from "react";
import { grey } from '@material-ui/core/colors';
import FaceDetectDialog from "../FaceDetection/FaceDetectionDialog";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            background: '#25272E',
            padding: '5%'
        },
        icon_bg: {
            background: '#555555',
            color: 'white'
        },
        icon_height: {
            height: '56px'
        },
        wrapper: {
            position: 'relative',
        },
        buttonProgress: {
            color: grey[900],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    })
);

interface IFormAttendance {
    checkIn: any,
    checkOut: any,
    attendances: Attendance[],
    loading: boolean,
    handleSetRefreshData: any
}

function FormAttendance({ checkIn, checkOut, attendances, loading, handleSetRefreshData }: IFormAttendance) {

    const classes = useStyles();
    const [pin, setPin] = useState<string>("");
    const [note, setNote] = useState<string>("");
    const [faceMsg, setFaceMsg] = useState<string>("");
    const [faceAlert, setFaceAlert] = useState<boolean>(false);
    const [checkInAlert, setCheckInAlert] = useState<boolean>(false);
    const [isFaceDetectDialogOpen, setIsFaceDetectDialogOpen] = useState<boolean>(false);

    const inputRef = React.useRef<any>(null);

    const convertDate_2_Datetime = (date: Date): string => {
        return date.getFullYear()
            + '-' + leftpad(date.getMonth() + 1, 2)
            + '-' + leftpad(date.getDate(), 2)
            + 'T' + leftpad(date.getHours(), 2)
            + ':' + leftpad(date.getMinutes(), 2)
            + ':' + leftpad(date.getSeconds(), 2);
    }

    const leftpad = (val: any, resultLength = 2, leftpadChar = '0'): string => {
        return (String(leftpadChar).repeat(resultLength)
            + String(val)).slice(String(val).length);
    }

    const onSubmit = () => {
        let formAtt: AttendanceForm = {
            attendId: pin,
            note: note,
            checkIn: convertDate_2_Datetime(new Date()),
            checkOut: null,
            isIncludeBreak: true
        }

        let isCheckIn: boolean = false
        //let isCheckOut: boolean = false
        let count: number = 0

        attendances.forEach(attend => {
            if (attend.attendId === formAtt.attendId) {
                isCheckIn = true;
                count = attend.count;
            }
        })

        if (!isCheckIn) {
            checkIn(formAtt);
        }
        else if (count !== 0) {
            checkOut(count, convertDate_2_Datetime(new Date()), note, true);
        }

        setPin("");
        setNote("");
    }

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            inputRef.current.focus();
        }
    }

    const closeAlert = () => {
        setCheckInAlert(false);
    }

    const closeFaceAlert = () => {
        setFaceAlert(false);
    }

    const openFaceAlert = (message: string) => {
        setFaceMsg(message);
        setFaceAlert(true);
    }

    const handleFaceLoginOnClick = () => {
        setIsFaceDetectDialogOpen(true);
    }

    const handleFaceLoginDialogClose = () => {
        setIsFaceDetectDialogOpen(false);
    }

    const device = /Android|iPad|iPhone/i.test(navigator.userAgent);

    return (
        <Box alignItems="center" className={classes.box}>
            <Alert 
                closeAlert={closeAlert} 
                alert={checkInAlert} 
                title="Failed" 
                text="You have already check in." />
            <Alert 
                closeAlert={closeFaceAlert} 
                alert={faceAlert} 
                title="Something went wrong" 
                text={faceMsg} />
            <form onSubmit={onSubmit}>
                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={11}>
                        <TextField fullWidth={true} label="AttendID" variant="filled" style={{ background: 'white' }}
                            type="password"
                            required
                            value={pin}
                            inputProps={{ maxLength: 4, inputMode: "numeric" }}
                            onChange={(e) => {
                                setPin(e.target.value)
                            }}
                            onKeyDown={(e) => handleKeyDown(e)}
                            inputRef={input => (!device && note === "") && (input && input.focus())} //autofocus
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.icon_bg}>
                        {loading ? <>
                            <Loading />
                        </> : <>
                            <Typography align="center" className={classes.icon_height} onClick={handleFaceLoginOnClick} >
                                <Face className={classes.icon_height} />
                            </Typography>
                        </>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth={true} label="Note" variant="filled" style={{ background: 'white' }}
                        type="text"
                        value={note}
                        inputProps={{ maxLength: 100 }}
                        onChange={(e) => {
                            setNote(e.target.value)
                        }}
                        onKeyDown={(e) => handleKeyDown(e)}
                    />
                </Grid>
                <Grid className={classes.wrapper}>
                    <Button
                        disabled={loading}
                        ref={inputRef}
                        fullWidth={true}
                        onClick={onSubmit}
                        style={{ background: '#FAC330' }}
                    >
                        Submit
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Grid>
            </form>
            {
                isFaceDetectDialogOpen
                    ? (<FaceDetectDialog open={isFaceDetectDialogOpen} setOpen={handleFaceLoginDialogClose} openFaceAlert={openFaceAlert} handleSetRefreshData={handleSetRefreshData}/>)
                    : ""
            }
        </Box>
    )
}

export default FormAttendance;