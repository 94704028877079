import { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import useAuthApi from "../../hook/useAuthAPI";
import Alert from "../Alert/Alert";
import { Auth } from "../../api/AuthAPI";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.mycostech.com/">
        Mycos Technologies
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LoginPage() {
  const classes = useStyles();
  const [loading, , getToken, alert, setAlert] = useAuthApi();
  const [attendId, setAttendId] = useState<string>("");

  const onLogin = async (e: any) => {
    e.preventDefault();
    const user: Auth = {
      email: "",
      password: attendId ?? "",
    };
    await getToken(user);
  };

  const closeAlert = () => {
    setAlert(false);
    setAttendId("");
  };

  return (
    <>
      <Alert
        closeAlert={closeAlert}
        alert={alert}
        title="Incorrect"
        text="Password is incorrect. Try again."
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={onLogin}>
            <TextField
              fullWidth={true}
              label="Password"
              variant="outlined"
              type="password"
              required
              value={attendId}
              onChange={(e: any) => {
                setAttendId(e.target.value);
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              // onClick={onLogin}
            >
              Sign In
            </Button>
          </form>
          {loading && <>Loading...</>}
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}

export default LoginPage;
