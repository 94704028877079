import { useCallback, useState } from "react";
import announceApi from "../api/AnnounceAPI";
import AnnounceData from "../models/Announce";

const useAnnounceAPI = () => {
    const [announce, setAnnounce] = useState<AnnounceData[]>([]);

    const getAnnounce = useCallback(
        async () => {
            const responseAnnounce = await announceApi.getAnnounce();
            setAnnounce(responseAnnounce.data);
            const interval = setInterval(async () => {
                const result = await announceApi.getAnnounce();
                setAnnounce(result.data);
            }, 1800000);
            return () => clearInterval(interval);
        },
        [setAnnounce],
    );

    return [getAnnounce, announce] as const;
}

export default useAnnounceAPI;