import Snackbar from "@material-ui/core/Snackbar";
import Alert, { Color } from "@material-ui/lab/Alert";

interface IAlertError {
    msg: string,
    open: boolean,
    setOpen: any,
    severity: Color | undefined
}

function SnackBarAlert({ msg, open, setOpen, severity }: IAlertError) {

    const onClose = () => { setOpen(false); }

    return (
        <>
            <Snackbar open={open} autoHideDuration={3500} onClose={onClose}>
                <Alert onClose={onClose} severity={severity}>
                    {msg}
                </Alert>
            </Snackbar>
        </>
    );
}

export default SnackBarAlert;