import { useCallback, useState } from "react";
import WeatherAPI from "../api/WeatherAPI";

const useNoteApi = () => {
    const [data, setData] = useState({ weather: [{ main: '', icon: '' }], name: '', main: { temp: 0, humidity: 0 }, sys: { sunset: 0, sunrise: 0, country: "" } });

    const getWeather = useCallback(
        async () => {
            const result = await WeatherAPI.getApi();
            setData(result.data);
            const interval = setInterval(async () => {
                const result = await WeatherAPI.getApi();
                setData(result.data);
            }, 300000);
            return () => clearInterval(interval);
        },
        [setData],
    );

    return [
        data,
        getWeather,
    ] as const;
}

export default useNoteApi;