import { Container, Typography } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";

function Clock() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const tick = () => { setTime(new Date()); };
    var timeID = setInterval(() => tick(), 1000);

    return () => {
      clearInterval(timeID);
    };
  }, [time]);

  return (
    <Container>
      <Typography variant="h3" align="center">
        {time.toLocaleTimeString()}
      </Typography>
      <Typography variant="h5" align="center">
        {moment(time).format('dddd, Do MMM YYYY')}
      </Typography>
    </Container>
  );
}

export default Clock;