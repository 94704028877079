import { useEffect } from "react";
import AnnounceData from "../../models/Announce";
import { makeStyles } from "@material-ui/core/styles";
import Carousel from "react-material-ui-carousel";
import { Grid } from "@material-ui/core";

interface AnnounceProps {
  getAnnounce: any;
  announce: AnnounceData[];
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    textAlign: "center",
    background: "#25272E",
    padding: "5%"
  },
  header: {
    alignItems: "center",
  },
  img: {
    height: "500px",
    objectFit: "contain",
    textAlign: "center",
    maxWidth: "100%",
  },
}));

function Announce({ getAnnounce, announce }: AnnounceProps) {
  const classes = useStyles();

  function sliceIntoChunks(arr: AnnounceData[], chunkSize: number) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      if (chunk[0].img !== null) {
        res.push(chunk);
      }
    }
    return res;
  }

  const picture = sliceIntoChunks(announce, 1);

  useEffect(() => {
    getAnnounce();
  }, [getAnnounce]);

  var pictureElement: any;

  if (picture) {
    pictureElement = picture.map((chunk, idx) => (
      <div key={idx}>
        {chunk.map((item) => {
          return (
            <div>
              <img
                alt={item?.msg}
                key={item.announceId}
                className={classes.img}
                src={`${process.env.REACT_APP_API_URL}` + item.img}
              />
            </div>
          );
        })}
      </div>
    ));
  }

  return (
    <Grid className={classes.root} >
      <Carousel interval={5000} className={classes.header}>
        {pictureElement}
      </Carousel>
    </Grid>
  );
}

export default Announce;
