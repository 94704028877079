import { useState } from "react";
import Airvisual from "../../components/Airvisual/Airvisual";
import Weather from "../../components/Weather/Weather";
import Clock from "../../components/Clock/Clock";
import FormAttendance from "../../components/FormAttendance/FormAttendance";
import AttendanceTable from "../../components/AttendanceTable/AttendanceTable";
import TextMarquee from "../../components/TextMarquee/TextMarquee";
import Announce from "../../components/Announce/Announce";
import useAttendanceApi from "../../hook/useAttendanceAPI";
import useAnnounceApi from "../../hook/useAnnounceAPI";
import {
  Container,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Alert from "../Alert/Alert";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      background: "#2D2E33",
      alignItems: "center",
      minHeight: "100vh",
      // minWidth: "100vw",
    },
    top_part: {
      background: "#25272E",
      color: "white",
      // paddingLeft: "1%",
      // paddingRight: "1%"
      padding: "1%"
    },
    body_part: {
      display: "flex",
      background: "#2D2E33",
      color: "white",
      flexGrow: 1,
      paddingLeft: "1%",
      paddingRight: "1%",
      paddingBottom: "1.5%"
    },
    copyright_part: {
      background: "#27282C",
      color: "white",
      // paddingLeft: "1%",
      // paddingRight: "1%"
      padding: "0.7%",
    },
  })
);

function Copyright() {
  return (
    <Typography variant="body2" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.mycostech.com/">
        Mycos Technologies
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Home() {
  const classes = useStyles();

  const [refreshData, setRefreshData] = useState<number>(0);

  const [
    getAttendances,
    attendances,
    attendance,
    CheckInAttendance,
    CheckOutAttendance,
    loading,
    alert,
    setAlert,
    EditNoteClient
  ] = useAttendanceApi();
  const [getAnnounce, announce] = useAnnounceApi();

  const closeAlert = () => {
    setAlert(false);
  }

  const handleSetRefreshData = () =>{
    setRefreshData(prevState => prevState + 1);
  }

  return (
    <>
      <Alert closeAlert={closeAlert} alert={alert} title="Not Found" text="Attend ID is not found. Try again." />
      {/* <Container maxWidth="xl" className={classes.root}> */}
      <Grid className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          className={classes.top_part}
        >
          <Grid item xs={4} md={4} lg={4}>
            <Airvisual />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Weather />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Clock />
          </Grid>
        </Grid>
        <Container maxWidth="xl" className={classes.body_part}>
          <Grid container direction="row" justify="space-around">
            <Grid container xs={12} md={12} lg={12}>
              <TextMarquee getAnnounce={getAnnounce} announce={announce} />
            </Grid>
            <Grid
              container
              direction="row"
              spacing={2}
            >
              <Grid item xs={12} md={6} lg={6} alignItems="center">
                <Announce getAnnounce={getAnnounce} announce={announce} />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormAttendance
                  checkIn={CheckInAttendance}
                  checkOut={CheckOutAttendance}
                  attendances={attendances}
                  loading={loading}
                  handleSetRefreshData={handleSetRefreshData}
                />
                <AttendanceTable
                  getAttendances={getAttendances}
                  attendances={attendances}
                  attendance={attendance}
                  loading={loading}
                  editNoteClient={EditNoteClient}
                  refreshData={refreshData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Grid container justify="center" className={classes.copyright_part} ><Copyright /></Grid>
      </Grid>
      {/* </Container> */}
    </>
  );
}

export default Home;