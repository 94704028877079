import { useEffect } from "react";
import AnnounceData from "../../models/Announce";
import Marquee from "react-fast-marquee";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import "./TextMarquee.css";

interface TextMarqueeProps {
  getAnnounce: any;
  announce: AnnounceData[];
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: "5px 150px 5px 5px", //top right bottom left
    },
  })
);

function TextMarquee({ getAnnounce, announce }: TextMarqueeProps) {
  useEffect(() => {
    getAnnounce();
  }, [getAnnounce]);

  const classes = useStyles();

  var announceElement: any;
  if (announce && announce.length > 0) {
    announceElement = announce.map(m => {
      if (m.isTextmarquee === true && m.msg !== null) {
        return (
          <Grid key={m.announceId} className={classes.root}>
            <Typography variant="h6"><ChatBubbleOutlineIcon style={{ marginRight: "10px" }} />{m.msg}</Typography>
          </Grid>
        );
      }

      return null;
    });
  }
 
  return (
    <Marquee speed={40} gradientWidth="0">
      { announceElement }
    </Marquee>
  );
}

export default TextMarquee;