import LoginPage from "./components/LoginPage/LoginPage";
import { Route, Switch } from "react-router";
import Home from "./components/Home/Home";
import { useEffect } from "react";
import Page404 from "./components/ResponPage/Page404";
import Page500 from "./components/ResponPage/Page500";

function App() {
  useEffect(() => {
    return () => {
      localStorage.clear();
    };
  });

  return (
    <>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/" exact component={Home} />
        <Route
          path="/error404"
          component={Page404}
        />
        <Route
          path="/error500"
          component={Page500}
        />
      </Switch>
    </>
  );
}

export default App;