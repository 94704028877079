import { useCallback, useState } from "react";
import AirvisualAPI from "../api/AirvisualAPI";

const useAirVisualAPI = () => {

    const [data, setData] = useState({ data: { city: '', country: '', current: { pollution: { aqius: 0 } } } });

    const getAirvisual = useCallback(
        async () => {
            const result = await AirvisualAPI.getApi()
            setData(result.data)
            const interval = setInterval(async () => {
                const result = await AirvisualAPI.getApi();
                setData(result.data);
            }, 3600000);
            return () => clearInterval(interval);
        },
        [setData],
    );

    return [
        data,
        getAirvisual,
    ] as const;
}

export default useAirVisualAPI;