import axios from 'axios';

const API_URL = process.env.REACT_APP_AIRVISUAL_API_URL;
const AIR_VISUAL_KEY = process.env.REACT_APP_AIRVISUAL_API_KEY;

const AirvisualAPI = {
    getApi: () => {
        return axios.get(API_URL + `lat=18.796258&lon=98.9679742&key=${AIR_VISUAL_KEY}`);
    }
}

export default AirvisualAPI;