import axios from "axios";
import { axiosInstance } from "../helper/axios";
import FaceDetectionFormModel from "../models/FaceDetectFormModel";
import Attendance from "../models/Attendance";

const FACE_DETECT_API_URL = process.env.REACT_APP_FACE_DETECT_API_URL;

const headers = {
    'Content-Type': 'application/json', // Set content type to JSON
};

const faceDetectionAPI = {
    sendImageDataToDetect: (imageDataURL: string) => {
        return axios.post(`${FACE_DETECT_API_URL}`, {
            image: imageDataURL.split(',')[1]
        }, {
            headers: headers
        });
    }
}

const faceDetectionMycosAPI = {
    loginWithFaceDetection: (user: FaceDetectionFormModel) => {
        return axiosInstance.post<Attendance>("api/Users/LoginWithFaceDetection", user);
    }
}

export { faceDetectionAPI, faceDetectionMycosAPI };