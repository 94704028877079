import { useCallback, useState } from "react";
import { faceDetectionAPI, faceDetectionMycosAPI } from "../api/FaceDetectionAPI";
import FaceDetectResultModel from "../models/FaceDetectResultModel";
import FaceDetectionFormModel from "../models/FaceDetectFormModel";
import Attendance from "../models/Attendance";

const useFaceDetectionAPI = () => {
    const [faceMatched, setFaceMatched] = useState<FaceDetectResultModel>();
    const [faceAuthResult, setFaceAuthResult] = useState<Attendance>();

    const sendImageDataToDetect = useCallback(async (imageDataURL: string) => {
        try {
            const result = await faceDetectionAPI.sendImageDataToDetect(imageDataURL);
            setFaceMatched(result.data);
        }
        catch (err) {
            console.log(err);
        }
    }, [setFaceMatched]);

    const authenticateByFaceDetect = useCallback(async (user: FaceDetectionFormModel) => {
        try {
            const result = await faceDetectionMycosAPI.loginWithFaceDetection(user);
            if (result && result.data) {
                setFaceAuthResult(result.data);
                return result;
            }
        }
        catch (err) {
            console.log(err);
        }
    }, [setFaceAuthResult]);

    return [sendImageDataToDetect, faceMatched, authenticateByFaceDetect, faceAuthResult] as const;
}

export default useFaceDetectionAPI;