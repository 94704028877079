import axios from "axios";
import Config from "../config";
import { history } from "./history";

const config = Config();

const _axiosInstance = axios.create({
  baseURL: (config.port === undefined || config.port === "") ? config.baseURL : `${config.baseURL}:${config.port}/`,
});

_axiosInstance.interceptors.request.use(async (config) => {
  let token = localStorage.getItem("token");
  if (token && token !== "") {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});

_axiosInstance.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Handle error from calling service
    if (error.response === undefined) {
      setTimeout(() => {
        history.push("/error500");
      });
    }
    else if (error.response.status === 401) {
      localStorage.removeItem("token");
      setTimeout(() => {
        history.push("/login");
      });
    }
    else if (error.response.status === 404) {
      setTimeout(() => {
        history.push("/error404");
      });
    }
    else if (error.response.status === 500) {
      setTimeout(() => {
        history.push("/error500");
      });
    }

    return new Promise((resolve, reject) => {
      reject(error);
    });

  }
);

export const axiosInstance = _axiosInstance;
