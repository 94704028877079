import { CircularProgress, Typography } from '@material-ui/core';

function Loading() {
    return (
        <Typography align="center" style={{ height: '56px' }}>
            <CircularProgress style={{ color: 'white', height: '56px' }} />
        </Typography>
    )
}

export default Loading;