import { useCallback, useState } from "react";
import attendanceApi from "../api/AttendanceAPI";
import Attendance from "../models/Attendance";
import FormAttendance from "../models/AttendanceForm";

const useAttendanceApi = () => {

    const [attendances, setAttendances] = useState<Attendance[]>([]);
    const [attendance, setAttendance] = useState<Attendance>();
    const [loading, setLoading] = useState<boolean>(false);
    const [alert, setAlert] = useState<boolean>(false);

    const getAttendances = useCallback(async (date1: any, date2: any) => {
        const attends = await attendanceApi.getAttendances(date1, date2);
        const newAttends = [...attends.data];
        setAttendances(newAttends);
    }, [setAttendances]);

    const CheckOutAttendance = useCallback(async (count: number, time: any, note: string, isIncludeBreak: boolean) => {
        try {
            setLoading(true);
            const attend = await attendanceApi.checkoutAttendances(count, time, note, isIncludeBreak);
            setAttendance(attend.data);
        }
        catch (err) {
            setAlert(true);
        }
        setLoading(false);
    }, [setAttendance, setLoading]);

    const CheckInAttendance = useCallback(async (formAttend: FormAttendance) => {
        try {
            setLoading(true);
            const newAttend = await attendanceApi.checkinAttendances(formAttend);
            setAttendance(newAttend.data);

            if (!newAttend.data) {
                setAlert(true);
            }
            else {
                setAttendances(data => [...data, newAttend.data]);
            }
        }
        catch (err) {
        }
        setLoading(false);
    }, [setAttendance, setLoading, setAlert, setAttendances]);

    const EditNoteClient = useCallback(async (count: number, note: string, isIncludeBreak: boolean) => {
        try {
            const editNote = await attendanceApi.editNoteClient(count, note, isIncludeBreak)
            setAttendance(editNote.data)
        }
        catch (err) {
        }
    }, [setAttendance]);

    return [getAttendances, attendances, attendance, CheckInAttendance, CheckOutAttendance, loading, alert, setAlert, EditNoteClient ] as const;
}

export default useAttendanceApi;