import { useEffect } from "react";
import useAirVisualAPI from "../../hook/useAirVisualAPI";
import { Container, Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';

function Airvisual() {
    const [data, getAirvisual] = useAirVisualAPI();

    const arrAir = {
        img: "",
        bg: "",
        text: ""
    }

    useEffect(() => {
        getAirvisual()
    }, [getAirvisual]);

    const dataAir = function (a: Number) {
        if (a <= 50) {
            arrAir.bg = "#a8e05f";
            arrAir.text = "Good";
            arrAir.img = "https://www.iqair.com/assets/aqi/ic-face-green.svg";
        }
        else if (a <= 100) {
            arrAir.bg = "#fdd64b";
            arrAir.text = "Moderate";
            arrAir.img = "https://www.iqair.com/assets/aqi/ic-face-yellow.svg";
        }
        else if (a <= 150) {
            arrAir.bg = "#ff9b57";
            arrAir.text = "Unhealthy for sensitive groups";
            arrAir.img = "https://www.iqair.com/assets/aqi/ic-face-orange.svg";
        }
        else if (a <= 200) {
            arrAir.bg = "#f65e5f";
            arrAir.text = "Unhealthy";
            arrAir.img = "https://www.iqair.com/assets/aqi/ic-face-red.svg";
        }
        else if (a <= 300 || a >= 300) {
            arrAir.bg = "#a97abc";
            arrAir.text = "Very Unhealthy";
            arrAir.img = "https://www.iqair.com/assets/aqi/ic-face-maroon.svg";
        }
    }

    return (
        <Container>
            <Card {...dataAir(data.data.current.pollution.aqius)} style={{ backgroundColor: arrAir.bg }}>
                <Grid container direction="row" justify="space-around" alignItems="center">
                    <Grid item xs={2} md={2} lg={2} >
                        <CardMedia component="img" src={arrAir.img} />
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                        <CardContent>
                            <Typography component="h5" variant="h5" align="center">
                                {data.data.current.pollution.aqius}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary" align="center">
                                US AQI
                            </Typography>
                        </CardContent>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                        <CardContent>
                            <Typography component="h6" variant="h6" align="center">
                                {arrAir.text}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary" align="center">
                                {data.data.city}, {data.data.country}
                            </Typography>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>
        </Container>
    );
}

export default Airvisual;
