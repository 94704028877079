import {
  Button,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import './pageRespon.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      background: "linear-gradient(180deg, #010101 30%, #4a4a4a 180%)",
    },
    btn: {
      marginTop: "25px",
      color: "#FFCB39",
      borderRadius: "20px"
    },
    responText: {
      color: "white",
      letterSpacing: "1em"
      //   marginBottom: "20px"
    },
    title: {
      color: "white",
      fontSize: "10rem",
      // fontWeight: "800"
      fontWeight: 800
    }
  })
);

function Page500() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root} >
        <div className="stars">
          <Container >
            <Grid container direction="row">
              <Grid direction="column" xs={10}>
                <img
                  src="http://salehriaz.com/404Page/img/earth.svg"
                  width="100px"
                  alt="earth"
                />
                <img src="http://salehriaz.com/404Page/img/moon.svg" width="80px" alt="moon" />
              </Grid>
              <Grid direction="column" xs={2}>
                <img
                  src="http://salehriaz.com/404Page/img/rocket.svg"
                  width="40px"
                  alt="rocket"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" >
              <Grid container direction="column" xs={12} justify="center" alignItems="center">
                <Grid item >
                  <Typography align="center" className={classes.title} variant="h1">500</Typography>
                  <Typography align="center" className={classes.responText} >INTERNAL SERVER</Typography>
                  <Typography align="center" className={classes.responText}>ERROR</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" xs={12} justify="center" alignItems="center">
                <Link to="/" style={{ textDecoration: "none" }} >
                  <Button variant="outlined" color="inherit" className={classes.btn}>
                    GO TO HOME
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <Grid direction="row" xs={12} style={{ marginLeft: "70%" }}>
              <img className="object_astronaut" src="http://salehriaz.com/404Page/img/astronaut.svg" width="140px" alt="astronaut" />
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Page500;
