import { axiosInstance } from '../helper/axios';
import Attendance from "../models/Attendance";
import AttendanceForm from '../models/AttendanceForm';

const attendanceApi = {
    getAttendances: (date1: any, date2: any) => {
        return axiosInstance.get<Attendance[]>("api/Attendance/" + date1 + "/" + date2);
    },
    checkinAttendances: (formAtt: AttendanceForm) => {
        return axiosInstance.post<Attendance>(`api/Attendance`, formAtt);
    },
    checkoutAttendances: (count: number, checkout: any, note: string, isIncludeBreak: boolean) => {
        return axiosInstance.put<Attendance>(`api/Attendance`, null, {
            params: {
                count: count,
                checkout: checkout,
                note: note,
                isIncludeBreak: isIncludeBreak
            }
        });
    },
    editNoteClient: (count: number, note: string, isIncludeBreak: boolean) => {
        return axiosInstance.put<Attendance>(`api/Attendance/EditNoteClient`, null, {
            params: {
                count: count,
                note: note,
                isIncludeBreak: isIncludeBreak
            }
        });
    }
}

export default attendanceApi;