import { useEffect, useState, useRef } from "react";
import { Typography } from '@material-ui/core';
import moment from "moment";
import useFaceDetectionAPI from "../../hook/useFaceDetectionAPI";
import useAttendanceApi from "../../hook/useAttendanceAPI";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import FaceDetectionFormModel from "../../models/FaceDetectFormModel";
import { setInterval, setTimeout } from "timers";

interface IDialog {
    open: boolean,
    setOpen: any,
    openFaceAlert: any,
    handleSetRefreshData: any
}

function FaceDetectDialog({ open, setOpen, openFaceAlert, handleSetRefreshData }: IDialog) {

    const [countdown, setCountdown] = useState<number>(3);
    const [submitCountDown, setSubmitCountDown] = useState<number>(5);
    const [isAutoSubmitStart, setIsAutoSubmitStart] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const photoRef = useRef<HTMLImageElement | null>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const submitButtonRef = useRef<HTMLButtonElement | null>(null);

    const [sendImageDataToDetect, faceMatched, authenticateByFaceDetect] = useFaceDetectionAPI();
    const [getAttendances] = useAttendanceApi();

    const handleCloseDialogClick = () => {
        setOpen(false);
    }

    const handleConfirmDetectClick = async () => {
        if (faceMatched) {
            const user: FaceDetectionFormModel = {
                email: faceMatched.matched.email,
                token: faceMatched?.token
            };

            const result = await authenticateByFaceDetect(user);

            if (result && result.status && (result.status === 200 || result.status === 204)) {
                if (result.data) {
                    await getAttendances(moment(new Date()).format("YYYY-MM-DD"), moment(new Date()).format("YYYY-MM-DD"));
                    handleSetRefreshData();

                    setIsAutoSubmitStart(false);
                    setSubmitCountDown(5);
                    setOpen(false);
                }
                else {
                    openFaceAlert("Can't match employee email in database. Please try it again");
                    setOpen(false);
                }
            }
            else {
                openFaceAlert("Something went wrong while attempting to authenticate via face detection feature");
                setOpen(false);
            }
        }
    }

    useEffect(() => {
        const constraints = { video: true };
        const getMedia = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                streamRef.current = stream;
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            }
            catch (err) {
                console.error('Error accessing camera:', err);
            }
        }

        const handleCaptureClick = () => {
            if (videoRef && videoRef.current) {
                // Creating a temporary canvas element to draw the frame
                const tempCanvas = document.createElement('canvas');
                tempCanvas.width = videoRef.current.videoWidth;
                tempCanvas.height = videoRef.current.videoHeight;
                const tempCtx = tempCanvas.getContext('2d');

                if (tempCtx) {
                    tempCtx.drawImage(videoRef.current, 0, 0, tempCanvas.width, tempCanvas.height);
                }

                // Getting the data URL representation of the captured frame
                const dataURL = tempCanvas.toDataURL('image/png');

                // Displaying the captured photo
                if (photoRef && photoRef.current) {
                    photoRef.current.src = dataURL;
                    sendImageDataToDetect(dataURL);
                }
            }
        }

        const autoFaceDetection = () => {
            let timer;
            if (videoRef && videoRef.current) {
                timer = setInterval(() => {
                    setCountdown((prevCount: number) => {
                        if (prevCount === 1) {
                            handleCaptureClick();
                        }
                        return prevCount - 1
                    });
                }, 1000);

                getMedia();
            }
            else {
                clearInterval(timer);
                setCountdown(5); // Reset count when dialog is closed
            }
        };

        const releaseCamera = () => {
            //console.log("Camera feature is released");
            const stream = streamRef.current;
            if (stream) {
                const tracks = stream.getTracks();
                tracks.forEach(track => {
                    track.stop();
                });
            }
        };

        setTimeout(() => {
            autoFaceDetection();
        }, 500);

        //Cleanup function to release video camera when component unmounts
        return () => {
            releaseCamera();
        };

    }, [videoRef, sendImageDataToDetect]);

    useEffect(() => {
        if (faceMatched && faceMatched.success && faceMatched.matched.name && faceMatched.matched.email) {
            setIsAutoSubmitStart(true);
        }
        else {
            setIsAutoSubmitStart(false);
        }
    }, [faceMatched]);

    useEffect(() => {
        if (!isAutoSubmitStart) {
            return;
        }

        const timer = setInterval(() => {
            setSubmitCountDown((prev) => Math.max(prev - 1, 0));
        }, 1000);

        if (submitCountDown === 0) {
            if (submitButtonRef && submitButtonRef.current) {
                submitButtonRef.current.click();
                clearInterval(timer);
                return;
            }
        }
    }, [isAutoSubmitStart, submitCountDown])

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseDialogClick}
                aria-labelledby="face-detection-dialog-title"
                aria-describedby="face-detection-dialog-description"
                maxWidth={"md"}
                fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title">Face Detection: {countdown > 0 ? `system will take a snapshot in ${countdown} seconds` : 'Snapshots have been captured'}</DialogTitle>

                <DialogContent>
                    <video ref={videoRef} width="100%" height="480" autoPlay playsInline></video>
                    <img
                        ref={photoRef}
                        alt="face detection feature"
                        src={'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>'}
                        style={{ display: "none" }} />
                    {
                        faceMatched
                            ? (faceMatched.success
                                ?
                                (
                                    <Typography variant="subtitle1" color="textSecondary" align="center">
                                        Are you {faceMatched.matched.name} ({faceMatched.matched.email})?
                                    </Typography>
                                )
                                :
                                (
                                    <Typography variant="subtitle1" color="textSecondary" align="center">
                                        Can't detect your face in tool. Please try it agian or enter PIN for clock-in/clock-out.
                                    </Typography>
                                )
                            )
                            : ""
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogClick} color="primary">
                        Cancel
                    </Button>
                    <Button 
                        ref={submitButtonRef}
                        onClick={handleConfirmDetectClick}
                        variant="contained"
                        color="primary"
                        disabled={((countdown > 0) || (faceMatched === null || (faceMatched && faceMatched.success === false))) ? true : false}
                    >
                        {submitCountDown > 0 ? `Auto submit in (${submitCountDown})` : 'Submitting...'}
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    )

}

export default FaceDetectDialog