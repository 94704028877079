import {
	Button,
	createStyles,
	Grid,
	IconButton,
	makeStyles,
	TableCell,
	TableRow,
	TextField,
	Theme,
} from "@material-ui/core";
import moment from "moment";
import Attendance from "../../models/Attendance";
import "./AttendanceTable.css";
import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { AccountCircle } from "@material-ui/icons";
import SaveIcon from "@material-ui/icons/Save";
import ConFirmDialog from "../ConFirmDialog/ConFirmDialog";
import SnackBarAlert from "../SnackBarAlert/SnackBarAlert";
import EditIcon from "@material-ui/icons/Edit";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		note: {
			whiteSpace: "normal",
			wordBreak: "break-word",
		},
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
			width: "30%"
		},
		margin: {
			margin: theme.spacing(1),
		},
		lightSwitch: {
			opacity: "50%",
			pointerEvents: "none"
		}
	})
);

interface AttendanceRowProps {
	count: number;
	attendance: Attendance;
	lastRowClass?: string;
	editNoteClient: any;
}

function AttendanceRow({
	count,
	attendance,
	lastRowClass = "",
	editNoteClient,
}: AttendanceRowProps) {
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);
	const handleOpen = () => { setOpen(true); };
	const handleClose = () => { setOpen(false); };

	const [editnote, setEditNote] = useState<any>("");
	const [inputAttendId, setinputAttendId] = useState<any>("");
	const [isIncludeBreak, setIsIncludeBreak] = useState(attendance ? attendance.isIncludeBreak : true);

	const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
	const displayConfirmDialog = () => { setOpenConfirmDialog(true); };
	const [alertWarning, setAlertWarning] = useState<boolean>(false);
	const [alertSuccess, setAlertSuccess] = useState<boolean>(false);
	const [alertError, setAlertError] = useState<boolean>(false);

	const editNoteForm = () => {
		// edit note
		if (inputAttendId === attendance.attendId) {
			editNoteClient(attendance.count, editnote, isIncludeBreak);
			setinputAttendId("");
			setEditNote("");
			setOpen(false);
			setAlertSuccess(true);
		}
		else {
			setAlertError(true);
		}
	};
	const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault();
		  	handleSaveButtonClick();
        }
    }

	const handleSaveButtonClick = () => {
		if (inputAttendId !== "") {
		  displayConfirmDialog();
		} else {
		  setAlertWarning(true);
		}
	};

	useEffect(() => {
		setIsIncludeBreak(attendance ? attendance.isIncludeBreak : true);
	}, [attendance])

	return (
		<>
			<TableRow className={lastRowClass}>
				<TableCell component="th" scope="row" align="center">
					{count}
				</TableCell>
				<TableCell align="center">{attendance.employeeName}</TableCell>
				<TableCell align="center">
					{moment(attendance.checkIn).format("HH:mm")}
				</TableCell>
				<TableCell align="center">
					{(attendance.checkOut === undefined ||
						attendance.checkOut === null) && <>--</>}
					{attendance.checkOut !== undefined &&
						attendance.checkOut !== null &&
						moment(attendance.checkOut).format("HH:mm")}
				</TableCell>
				<TableCell>
					<Switch color="primary" size="small" checked={isIncludeBreak} className={classes.lightSwitch}/>
				</TableCell>
				<TableCell className={classes.note}>
					{attendance.note}
				</TableCell>
				<TableCell>
					{
						attendance.checkOut !== null ? (
							<IconButton
								style={{
									padding: "5px",
									margin: "0px",
									marginRight: "10px",
								}}
								onClick={handleOpen}
								color="inherit"
								aria-label="upload picture"
								component="span"
							>
								<EditIcon fontSize="small" />
							</IconButton>
						) : ""
					}
				</TableCell>
				<TableCell align="center">
					{attendance.hours !== null ? (
						<>{Number(attendance.hours).toFixed(2)}</>
					) : (
						<>--</>
					)}
				</TableCell>
			</TableRow>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<div className={classes.paper}>
						<h2 id="transition-modal-title">{attendance.employeeName}</h2>
						<form>
							<div className={classes.margin}>
								<Grid container spacing={1} alignItems="flex-end" justify="center" >
									<Grid item>
										<AccountCircle />
									</Grid>
									<Grid >
										<TextField
											id="input-with-icon-grid"
											label="AttendID"
											type="password"
											required
											value={inputAttendId}
											inputProps={{ maxLength: 4, inputMode: "numeric" }}
											onChange={(e) => {
												setinputAttendId(e.target.value);
											}}
											onKeyDown={handleKeyDown}
										/>
									</Grid>
								</Grid>
								<Grid style={{ marginTop: "15px" }}>
									<TextField
										label="Note"
										id="outlined-size-normal"
										variant="outlined"
										fullWidth
										required
										value={editnote}
										inputProps={{ maxLength: 100 }}
										onChange={(e) => {
											setEditNote(e.target.value);
										}}
										multiline
										rows={7}
									/>
								</Grid>
								<Grid style={{ marginTop: "15px" }}>
									<FormControlLabel control={
										<Switch checked={isIncludeBreak} color="primary" onChange={(e) => {
											setIsIncludeBreak(e.target.checked);
										}} />
									} label="Include a lunch break time" />
								</Grid>
								<Grid
									container
									justify="flex-end"
									style={{ marginTop: "15px" }}
								>
									<Button
										style={{ marginRight: "10px" }}
										onClick={() => {
											setOpen(false);
											setIsIncludeBreak(attendance.isIncludeBreak);
										}}
										color="primary"
									>
										Cancel
									</Button>
									<Button
										type="button"
										variant="contained"
										color="primary"
										size="small"
										startIcon={<SaveIcon />}
										onClick={handleSaveButtonClick}
									>
										Save
									</Button>
								</Grid>
								<ConFirmDialog
									title="Worklog"
									description="Do you want to update your worklog?"
									open={openConfirmDialog}
									setOpen={setOpenConfirmDialog}
									actionDialog={editNoteForm}
								/>
								<SnackBarAlert
									msg="Please enter all fileds."
									open={alertWarning}
									setOpen={setAlertWarning}
									severity="warning"
								/>
								<SnackBarAlert
									msg="Incorrect AttendID."
									open={alertError}
									setOpen={setAlertError}
									severity="error"
								/>
							</div>
						</form>
					</div>
				</Fade>
			</Modal>
			<SnackBarAlert
				msg="Success !"
				open={alertSuccess}
				setOpen={setAlertSuccess}
				severity="success"
			/>
		</>
	);
}

export default AttendanceRow;